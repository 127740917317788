import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';

import { BehaviorSubject, lastValueFrom, takeUntil } from 'rxjs';
import notify from 'devextreme/ui/notify';

import { HttpReservationService } from 'src/app/services/http/http-reservation.service';
import { Reservation } from 'src/app/models/view-models/reservation';
import { BaseSubscriptionComponent } from 'src/app/base/base.suscription.component';
import { setCorrectDateTimeForBackend } from 'src/app/helper/common-helper';
import { HttpCodeService } from 'src/app/services/http/http-code.service';
import { HttpUserService } from 'src/app/services/http/http-user.service';
import { Therapist } from 'src/app/models/view-models/therapist';
import { TreatmentType } from 'src/app/models/view-models/treatment-type';
import { ModeComponentEnum } from 'src/app/models/enums/mode-component';
import {
  CreateUpdateReservation,
  CreateUpdateReservationSubticket,
} from 'src/app/models/requests/create-update-reservation';
import { Subticket } from 'src/app/models/view-models/subticket';
import { SubticketType } from 'src/app/models/view-models/subticket-type';
import { SubticketTypeEnum } from 'src/app/models/enums/subticket-type.enum';
import * as moment from 'moment';
import { ReservationScheduler } from 'src/app/models/view-models/reservation-scheduler';
import { TreatmentRoom } from 'src/app/models/view-models/treatment-room';
import { ReservationDuration, getTimeString } from 'src/app/models/view-models/reservation-duration';
import validationEngine from 'devextreme/ui/validation_engine';
import { ExceptionBackModel } from 'src/app/models/view-models/exception-back-model';
import { ExceptionBackModelEnum } from 'src/app/models/enums/exception-back-model-enum';

@Component({
  selector: 'app-reservation-detail',
  templateUrl: './reservation-detail.component.html',
})
export class ReservationDetailComponent extends BaseSubscriptionComponent implements OnInit {
  public reservation: Reservation;

  public agents: any[];
  public therapists: Therapist[];
  public treatmentTypes: TreatmentType[];
  public durations: ReservationDuration[] = [];

  public isDueDateValid: boolean = true;
  public dueDateError = {};
  public shouldDisableTreatmentRoom: boolean = true;

  private mode: ModeComponentEnum;

  @Input() modeChanged: BehaviorSubject<[ModeComponentEnum, ReservationScheduler]>;
  @Input() allTreatmentRooms: TreatmentRoom[];
  @Input() subticketTypes: SubticketType[];

  @Output() saved = new EventEmitter();
  @Output() deleted = new EventEmitter();
  @Output() canceled = new EventEmitter();

  constructor(
    private httpReservationService: HttpReservationService,
    private httpCodesService: HttpCodeService,
    private httpUserService: HttpUserService
  ) {
    super();
  }

  public async ngOnInit(): Promise<void> {
    await this.loadInitialData();

    this.modeChanged.pipe(takeUntil(this.destroyed)).subscribe(async (modeData) => {
      this.mode = modeData[0] as ModeComponentEnum;
      const reservationScheduler = modeData[1] as ReservationScheduler;

      if (this.mode === ModeComponentEnum.Create) {
        await this.setPageForCreation(reservationScheduler);
      } else if (this.mode === ModeComponentEnum.Update) {
        await this.setPageForEdition(reservationScheduler);
      }

      // Tretment Room
      if (reservationScheduler.treatmentRoomId === null) {
        this.shouldDisableTreatmentRoom = false;
        this.reservation.TreatmentRoom = null;
      } else {
        this.shouldDisableTreatmentRoom = true;
        this.reservation.TreatmentRoom = this.allTreatmentRooms.find(
          (_) => _.Id === reservationScheduler.treatmentRoomId
        );
      }
    });
  }

  public validateDueDate(): void {
    if (!this.reservation.Subticket.DueDate) {
      this.dueDateError = { message: 'Plase select a Date & Time' };
      this.isDueDateValid = false;
      return;
    }
    this.isDueDateValid = true;
  }

  public saveReservation(): void {
    this.validateDueDate();
    if (!this.isDueDateValid) {
      notify(this.dueDateError['message'], 'error');
      return;
    }

    var validator = validationEngine.validateGroup();
    if (!validator.isValid) {
      return;
    }

    const dataToSend = this.setDataToSend();

    if (this.mode === ModeComponentEnum.Create) {
      dataToSend.Id = 0;
      dataToSend.Subticket.Id = 0;

      this.httpReservationService
        .insertReservation(dataToSend)
        .pipe(takeUntil(this.destroyed))
        .subscribe({
          next: () => {
            this.saved.emit('OK');
          },
          error: (error) => {
            // TODO: Should be create Error interceptor for all app
            if (error.error) {
              const exModel = error.error as ExceptionBackModel;
              if (exModel.Type === ExceptionBackModelEnum.Business) {
                notify(exModel.Description, 'error');
                return;
              }
            }
            notify('Internal Error', 'error');
          },
        });
    } else if (this.mode === ModeComponentEnum.Update) {
      this.httpReservationService
        .updateReservation(dataToSend)
        .pipe(takeUntil(this.destroyed))
        .subscribe({
          next: () => {
            this.saved.emit('OK');
          },
          error: (error) => {
            // TODO: Should be create Error interceptor for all app
            if (error.error) {
              const exModel = error.error as ExceptionBackModel;
              if (exModel.Type === ExceptionBackModelEnum.Business) {
                notify(exModel.Description, 'error');
                return;
              }
            }
            notify('Internal Error', 'error');
          },
        });
    }
  }

  public deleteReservation(): void {
    if (!this.reservation.Id) {
      return;
    }

    this.httpReservationService
      .deleteReservation(this.reservation.Id)
      .pipe(takeUntil(this.destroyed))
      .subscribe({
        next: () => this.deleted.emit('OK'),
        error: (error) => {
          notify('Error trying deleting the Reservation, please try again later', 'error', 10000);
        },
      });
  }

  public async cancelReservation(): Promise<void> {
    this.canceled.emit('OK');
  }

  public searchTherapistsByTreatment(): void {
    this.reservation.Therapist = null;
    this.therapists = [];

    this.httpReservationService
      .getTherapistsByTreatmentType(this.reservation.TreatmentType.Id)
      .pipe(takeUntil(this.destroyed))
      .subscribe((therapists) => {
        this.therapists = therapists;
      });
  }

  private async setPageForCreation(reservationScheduler: ReservationScheduler): Promise<void> {
    this.reservation = new Reservation();

    this.reservation.Subticket = new Subticket();
    this.reservation.Subticket.SubticketType = this.subticketTypes.find((_) => _.Id === SubticketTypeEnum.Spa);

    this.reservation.Subticket.DueDate = reservationScheduler.startDate;
    this.reservation.Duration = this.durations[0].Value; // 15 minutes for default
  }

  private async setPageForEdition(reservationScheduler: ReservationScheduler): Promise<void> {
    this.reservation = Object.assign({}, reservationScheduler.reservation);

    this.reservation.Subticket.Asignee = this.agents.find((_) => _.Id === this.reservation.Subticket.AsigneeId);
    this.reservation.TreatmentType = this.treatmentTypes.find((_) => _.Id === this.reservation.TreatmentType.Id);

    this.therapists = await lastValueFrom(
      this.httpReservationService
        .getTherapistsByTreatmentType(this.reservation.TreatmentType.Id)
        .pipe(takeUntil(this.destroyed))
    );

    this.reservation.Therapist = this.therapists.find((_) => _.Id === this.reservation.Therapist.Id);

    // Should be string from back, fix compatibility problems
    const durationToCompate = this.reservation.Duration + '';

    this.reservation.Duration = this.durations.find(
      (duration) => getTimeString(duration.Value) == durationToCompate
    ).Value;
  }

  private async loadInitialData(): Promise<void> {
    this.agents = await lastValueFrom(this.httpUserService.getAgents().pipe(takeUntil(this.destroyed)));

    this.treatmentTypes = await lastValueFrom(
      this.httpCodesService.getTreatmentTypes().pipe(takeUntil(this.destroyed))
    );

    // Set Durations
    this.durations = [
      { Name: '15 Minutes', Value: moment.duration(15, 'minutes') },
      { Name: '30 Minutes', Value: moment.duration(30, 'minutes') },
      { Name: '45 Minutes', Value: moment.duration(45, 'minutes') },
      { Name: '1 Hour', Value: moment.duration(1, 'hours') },
      { Name: '1 Hour 15 Minutes', Value: moment.duration(75, 'minutes') },
      { Name: '1 Hour 30 Minutes', Value: moment.duration(90, 'minutes') },
      { Name: '1 Hour 45 Minutes', Value: moment.duration(105, 'minutes') },
      { Name: '2 Hours', Value: moment.duration(2, 'hours') },
    ];
  }

  private setDataToSend(): CreateUpdateReservation {
    const dataToSend = new CreateUpdateReservation();

    dataToSend.Id = this.reservation.Id;
    dataToSend.Duration = getTimeString(this.reservation.Duration);
    dataToSend.TreatmentTypeId = this.reservation.TreatmentType.Id;
    dataToSend.TreatmentRoomId = this.reservation.TreatmentRoom.Id;
    dataToSend.TherapistId = this.reservation.Therapist.Id;

    dataToSend.Subticket = new CreateUpdateReservationSubticket();
    dataToSend.Subticket.Id = this.reservation.Subticket.Id;
    dataToSend.Subticket.DueDate = setCorrectDateTimeForBackend(this.reservation.Subticket.DueDate);
    dataToSend.Subticket.GuestName = this.reservation.Subticket.GuestName;
    dataToSend.Subticket.NumberOfGuests = this.reservation.Subticket.NumberOfGuests;
    dataToSend.Subticket.Notes = this.reservation.Subticket.Notes;
    dataToSend.Subticket.AsigneeId = this.reservation.Subticket.Asignee ? this.reservation.Subticket.Asignee.Id : null;

    return dataToSend;
  }
}
